<template>
    <div>
        <v-slide-y-transition group hide-on-leave>
            <div v-if="settings != null && settings.data.showMore" key="1">
                <slot></slot>
            </div>
            <div class="text-center" key="2">
            <BT-Btn
                block
                @click="settings.data.showMore = !settings.data.showMore"
                class="primary--text mx-auto"
                inline
                :label="settings.data.showMore ? 'Hide Info' : 'Show More Info'"
                :leftIcon="settings.data.showMore ? 'mdi-arrow-up' : 'mdi-arrow-down'"
                outlined
                small />
        </div>
        </v-slide-y-transition>
    </div>
    
    <!-- <v-alert border="left" colored-border color="primary">
        <div class="text-body-2">
            <slot v-bind:text="text">{{ text }}</slot>
        </div>
    </v-alert> -->
</template>

<script>
export default {
    name: 'Setup-More',
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>